import React from "react"
import { css } from "@emotion/react"

export default function Confidence() {
  return (
    <div css={GuaranteesSectionStyle} style={{
      id: "section-guarantees"
    }}
    >
      <div>
        <h2>are you up to date?</h2>
        <h5>Health is demanding</h5>
        <p>
          To avoid health’s authorithies rejection, your methods need to
          be up to date with all the good practices.
        </p>
        <div id="guarantees-list-ctn">
          <div className="guarantee-ctn">
            <svg
              id="note-board-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 376 456"
            >
              <use href="/icons-sprite-map.svg#note-board-icon" />
            </svg>
            <div>
              <h4>norms certifications:</h4>
              <ul>
                <li>
                  <svg
                    className="checkmark-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 17"
                  >
                    <use href="/icons-sprite-map.svg#checkmark-icon" />
                  </svg>
                  <span>ICH - GxP</span>
                </li>
                <li>
                  <svg
                    className="checkmark-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 17"
                  >
                    <use href="/icons-sprite-map.svg#checkmark-icon" />
                  </svg>
                  <span>FDA (Title 21 CFR Part 11)</span>
                </li>
                <li>
                  <svg
                    className="checkmark-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 17"
                  >
                    <use href="/icons-sprite-map.svg#checkmark-icon" />
                  </svg>
                  <span>Data Protection (GDPR)</span>
                </li>
                <li>
                  <svg
                    className="checkmark-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 17"
                  >
                    <use href="/icons-sprite-map.svg#checkmark-icon" />
                  </svg>
                  <span>HIPAA</span>
                </li>
                <li>
                  <svg
                    className="checkmark-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 17"
                  >
                    <use href="/icons-sprite-map.svg#checkmark-icon" />
                  </svg>
                  <span>Internal SOPs</span>
                </li>
                <li>
                  <svg
                    className="checkmark-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 17"
                  >
                    <use href="/icons-sprite-map.svg#checkmark-icon" />
                  </svg>
                  <span>EMA</span>
                </li>
                <li>
                  <svg
                    className="checkmark-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 17"
                  >
                    <use href="/icons-sprite-map.svg#checkmark-icon" />
                  </svg>
                  <span>GAMP 5</span>
                </li>
                <li>
                  <svg
                    className="checkmark-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 17"
                  >
                    <use href="/icons-sprite-map.svg#checkmark-icon" />
                  </svg>
                  <span>PICS</span>
                </li>
                <li>
                  <svg
                    className="checkmark-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 17"
                  >
                    <use href="/icons-sprite-map.svg#checkmark-icon" />
                  </svg>
                  <span>ISO 9001</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

const GuaranteesSectionStyle = css`

  #section-guarantees {
    margin-bottom: -0.34rem;
  }

  h2 {
    color: #080829;
    margin-top: 7rem;
  }

  h5 {
    color: #080829;
  }

  p {
    font-weight: 300;
    color: #1d2554;
    text-align: center;
    margin: auto;
    max-width: 24rem;
  }

  #guarantees-list-ctn {
    max-width: 59.53rem;
    margin: auto;
  }

  #note-board-icon {
    width: 18.05rem;
    max-width: 100%;
  }

  .checkmark-icon {
    min-width: 0.96rem;
    max-height: 0.816rem;
    padding-right: 0.6rem;
  }

  .guarantee-ctn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 8rem;
  }

  .guarantee-ctn > div {
    width: 32rem;
    margin-left: 5rem;
  }

  .guarantee-ctn h4 {
    color: #1d2554;
    font-size: 1.2rem;
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 0.9rem;
  }

  .guarantee-ctn ul {
    list-style-type: none;
    padding: 0;
    font-weight: 300;
    color: #1d2554;
  }

  .guarantee-ctn ul li {
    padding: 0.5rem;
    border-bottom: 0.144rem solid #c6c8d4;
    display: flex;
  }

  @media (max-width: 1023px) {
    #guarantees-content-ctn > p {
      margin-bottom: 2rem;
    }

    .guarantee-ctn {
      display: block;
    }

    .guarantee-ctn > div {
      max-width: 32rem;
      width: initial;
      margin: auto;
    }

    .guarantee-ctn > svg {
      display: block;
      margin: auto;
      margin-bottom: 3rem;
    }

    #note-board-icon {
      width: 14.445rem;
    }
  }

  @media (max-width: 479px) {
    #note-board-icon {
      width: 13rem;
    }
  }
`