import React from "react"
import "../assets/styles/global.css"

import { graphql, useStaticQuery } from "gatsby"
import HeaderServices from "../components/Header/HeaderServices/HeaderServices"
import FullPageLayout from "../layouts/FullPageLayout"
import Section from "../components/Section/Section"
import Results from "../components/Content/Results"
import Confidence from "../components/Content/Confidence"
import DelimiterSVG, { SVGDelimiter } from "../components/Section/SVGDelimiter"
import Testimonials from "../components/Content/Testimonials/Testimonials"
import ContactComponent from "../components/Content/Contact/ContactComponent"
import Footer from "../components/Bar/footer"
import { QUALITY_ASSURANCE_TOOLTIPS } from "../const/MenuTooltips"
import { SEO_OPTIONS_QUALITY } from "../const/SEOOptions"

const QualityAssurancePage = ({location}) => {
  const data = useStaticQuery(graphql`
    {    
      headerImage: file(relativePath: { eq: "quality-assurance.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  const fullpageOptions = {
    navigationTooltips: QUALITY_ASSURANCE_TOOLTIPS
  }

  return (
    <FullPageLayout
      options={fullpageOptions}
      SEOOptions={SEO_OPTIONS_QUALITY}

      background={"white"}
      location={location}
    >

      <Section isFirstSection>
        <HeaderServices
          h1={`<span>quality</span><br/><span>assurance</span>`}
          p={`Consulting &amp; auditing to ensure<br/>validity and avoid
                painful surprises.`}
          img={data.headerImage.childImageSharp.fluid}
          alt={`Quality assurance`}
        />

      </Section>
      <Section
      >
        <Results />
      </Section>

      <Section
        autoHeight
      >
        <Confidence />
      </Section>

      <DelimiterSVG
        shape={SVGDelimiter.Shape.Line}
        fillColor={"#fff"}
        Background={`#010921`}
      />

      <Section
        Background={`#010921`}
      >
        <Testimonials />
      </Section>

      <DelimiterSVG
        shape={SVGDelimiter.Shape.Line}
        fillColor={`#010921`}
        Background={`transparent`}
      />

      <ContactComponent
        DisplayContactForm={true}
      />
      <Footer location={location} />
    </FullPageLayout>
  )
}

export default QualityAssurancePage