import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/react"

const Results = () => {
  return (
    <div css={ResultsSectionStyles}>
      <section id="section-results" className="gutter">
        <h2>confirmation for confidence</h2>
        <h5>We ensure that you can fully trust our results</h5>
        <div className="result-ctn">
          <svg
            id="document-medium-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 268 255"
          >
            <use href="/icons-sprite-map.svg#document-medium-icon" />
          </svg>
          <div>
            <h4>audit</h4>
            <ul>
              <li>&gt; Qualification Audits</li>
              <li>&gt; Internal &amp; External Audits</li>
              <li>
                &gt; Independant DataBase Audits (setup, validation,
                documentation, change control, risk impact analysis...)
              </li>
              <li>
                &gt; Corrective and Preventive Actions (CAPA) managagement and
                coordination
              </li>
            </ul>
          </div>
        </div>
        <Link className="cta-link" to="/contact-us/">
          talk to our expert
        </Link>
        <hr />
        <div className="result-ctn">
          <svg
            id="people-medium-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 283 258"
          >
            <use href="/icons-sprite-map.svg#people-medium-icon" />
          </svg>
          <div>
            <h4>consulting</h4>
            <ul>
              <li>&gt; Diagnostics</li>
              <li>&gt; Reports</li>
              <li>&gt; Recommandations</li>
              <li>&gt; Setups</li>
              <li>&gt; Trainings</li>
            </ul>
          </div>
        </div>
        <Link className="cta-link" to="/contact-us/">
          talk to our expert
        </Link>
        <hr />
      </section>
    </div>
  )
}

export default Results

const ResultsSectionStyles = css`
  #section-results h2 {
    color: #080829;
  }

  #section-results h5 {
    color: #080829;
    margin-bottom: 6rem;
  }

  #section-results hr {
    background-color: #080829;
    height: 0.14rem;
    margin-bottom: 9.12rem;
  }

  #section-results .cta-link {
    color: #080829;
  }

  #section-results .cta-link:hover {
    color: #05259f;
  }

  .result-ctn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    max-width: 44.8rem;
    box-sizing: border-box;
    border: 0.24rem solid #080829;
    margin: auto auto 3rem auto;
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;
    border-radius: 1.6rem;
    svg{
      margin : auto 5em;
    }
  }

  .result-ctn h4 {
    text-transform: uppercase;
    font-weight: 500;
    color: #080829;
  }

  .result-ctn > div {
    max-width: 16.32rem;
    margin-left: 2em;
  }

  .result-ctn ul {
    list-style-type: none;
    padding: 0;
  }

  .result-ctn ul li {
    font-weight: 300;
    color: #1d2554;
    margin-bottom: 0.9rem;
  }

  #document-medium-icon {
    width: 12.86rem;
    max-width: 100%;
  }

  #people-medium-icon {
    width: 13.58rem;
    max-width: 100%;
  }

  @media (max-width: 1023px) {
    #section-results hr {
      margin-bottom: 7rem;
    }
  }

  @media (max-width: 767px) {
    #section-results hr {
      margin-bottom: 4rem;
    }

    .result-ctn {
      flex-direction: column;
      max-width: fit-content;
      padding: 3rem;
    }

    .result-ctn ul:last-of-type,
    .result-ctn ul li:last-of-type {
      margin-bottom: 0;
    }

    .result-ctn svg {
      margin-bottom: 1rem;
    }

    .result-ctn h4 {
      text-align: center;
    }

    .result-ctn ul li {
      text-align: center;
    }
  }
`
